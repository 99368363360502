<template>
    <img
        v-if="url"
        :src="url"
        alt="logo"
        data-testid="logo"
    >
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class Logo extends Vue {

    url: string | null = null;

    listener!: number;

    created(): void {
        this.setURL();

        this.listener = this.$style.addThemeListener(() => {
            this.setURL();
        });
    }

    setURL(): void {
        switch (this.$style.getAppliedTheme()) {
            case 'dark':
                this.url = this.$whitelabel.resources.logoDark;
                break;
            case 'light':
            default:
                this.url = this.$whitelabel.resources.logo;
                break;
        }
    }

    destroyed(): void {
        if (this.listener !== undefined) {
            this.$style.removeThemeListener(this.listener);
        }
    }

}
</script>
