<template>
    <div
        v-if="
            showDownloadButton ||
                isAppicEnabled ||
                isPartyPayEnabled ||
                isMaveEnabled ||
                isTicketKeeperEnabled ||
                isAppleWalletEnabled ||
                isCloseEnabled
        "
        class="order-ticket-actions"
    >
        <AppleWallet
            v-if="isAppleWalletEnabled && ticket"
            :ticket="ticket"
        />
        <a
            v-if="isAppicEnabled"
            :href="appicUrl"
            class="order-ticket-actions__btn"
            target="_blank"
        >
            <button class="ot-button is-fullwidth appic">
                <i18n path="order.components.order_ticket_actions.open_in">
                    <template #logo>
                        <img
                            src="../../../../assets/images/brands/appic.svg"
                            alt="Appic"
                        >
                    </template>
                </i18n>
            </button>
        </a>
        <a
            v-if="isCloseEnabled"
            :href="closeUrl"
            class="order-ticket-actions__btn"
            target="_blank"
        >
            <button class="ot-button is-fullwidth close">
                <i18n path="order.components.order_ticket_actions.open_in">
                    <template #logo>
                        <img
                            src="../../../../assets/images/brands/close.svg"
                            alt="Close"
                        >
                    </template>
                </i18n>
            </button>
        </a>
        <a
            v-if="isMaveEnabled"
            :href="maveUrl"
            class="order-ticket-actions__btn"
            target="_blank"
        >
            <button class="ot-button is-fullwidth mave">
                <i18n path="order.components.order_ticket_actions.open_in">
                    <template #logo>
                        <img
                            src="../../../../assets/images/brands/mave.svg"
                            alt="Mave"
                            class="mave-logo"
                        >
                        &nbsp;&nbsp;Mave
                    </template>
                </i18n>
            </button>
        </a>
        <a
            v-if="isPartyPayEnabled"
            :href="partyPayUrl"
            class="order-ticket-actions__btn"
            target="_blank"
        >
            <button class="ot-button is-fullwidth party-pay">
                <i18n path="order.components.order_ticket_actions.open_in">
                    <template #logo>
                        <img
                            src="../../../../assets/images/brands/party-pay.svg"
                            alt="Party Pay"
                        >
                    </template>
                </i18n>
            </button>
        </a>
        <a
            v-if="isTicketKeeperEnabled"
            :href="ticketKeeperUrl"
            class="order-ticket-actions__btn"
            target="_blank"
        >
            <button class="ot-button is-fullwidth ticket-keeper">
                <i18n path="order.components.order_ticket_actions.open_in">
                    <template #logo>
                        <img
                            src="../../../../assets/images/brands/ticket-keeper.svg"
                            alt="Ticket Keeper"
                        >
                    </template>
                </i18n>
            </button>
        </a>
        <a
            v-if="showDownloadButton && downloadLink"
            target="_blank"
            class="order-ticket-actions__btn"
            :href="downloadLink"
        >
            <button class="ot-button is-fullwidth">
                <i class="oti oti-download ot-button-icon--left" />
                {{ $t('order.components.order_ticket_actions.download') }}
            </button>
        </a>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import urljoin from 'url-join';
import type { IOrderTicket } from '@openticket/lib-order';
import { OrderLinkReplacer } from '../../../../utils/linkreplacer';
import AppleWallet from './actions/AppleWallet.vue';

@Component({
    components: {
        AppleWallet,
    },
})
export default class OrderTicketActions extends Vue {

    @Prop() showDownloadButton!: boolean;
    @Prop() downloadLink!: string;

    @Prop() ticket!: IOrderTicket;

    get linkReplacer(): OrderLinkReplacer {
        return new OrderLinkReplacer(this.$order, this.ticket);
    }

    get isAppicEnabled(): boolean {
        return (
            !!this.$settings
            && !!this.$settings.static.order.enableAppic
            && !!this.$whitelabel.order.appic_url
            && this.$order.data.status === 'paid'
        );
    }

    get isCloseEnabled(): boolean {
        return (
            !!this.$settings
            && !!this.$settings.static.order.components.eventCard.closeUrl
            && this.$order.data.status === 'paid'
        );
    }

    get isPartyPayEnabled(): boolean {
        return (
            !!this.$settings
            && !!this.$settings.static.order.enablePartyPay
            && !!this.$whitelabel.order.party_pay_url
            && this.$order.data.status === 'paid'
        );
    }

    get isMaveEnabled(): boolean {
        return (
            !!this.$settings
            && !!this.$settings.static.order.enableMave
            && !!this.$whitelabel.order.mave_url
            && this.$order.data.status === 'paid'
        );
    }

    get isTicketKeeperEnabled(): boolean {
        return (
            !!this.$settings
            && !!this.$settings.static.order.enableTicketKeeper
            && !!this.$whitelabel.order.ticket_keeper_url
            && this.$order.data.status === 'paid'
        );
    }

    get isAppleWalletEnabled(): boolean {
        if (
            this.$whitelabel.order.wallet_url
            && this.$settings
            && this.$settings.static.order.enablePassbook
            && this.$order.data.status === 'paid'
            && !this.$whitelabel.order.disable_passbook
        ) {
            return window.IsAppleDevice || false;
        }

        return false;
    }

    get appicUrl(): string {
        if (!this.$whitelabel.order.appic_url) {
            return '';
        }

        return urljoin(
            this.$whitelabel.order.appic_url,
            `?order_uuid=${this.$order.data.guid}`,
        );
    }

    get closeUrl(): string {
        if (
            !this.$settings
            || !this.$settings.static.order.components.eventCard.closeUrl
        ) {
            return '';
        }

        return this.$settings.static.order.components.eventCard.closeUrl;
    }

    get partyPayUrl(): string {
        if (!this.$whitelabel.order.party_pay_url) {
            return '';
        }

        let party_pay_url = this.$whitelabel.order.party_pay_url.replace(
            /{{\s*order_id\s*}}/,
            this.$order.data.guid,
        );

        if (this.ticket) {
            party_pay_url = urljoin(
                party_pay_url,
                `?event_id=${this.ticket.ticket.event_id}`,
                `?ticket_id=${this.ticket.ticket.guid}`,
                `?order_ticket_id=${this.ticket.guid}`,
            );
        }

        return party_pay_url;
    }

    get maveUrl(): string {
        if (!this.$whitelabel.order.mave_url) {
            return '';
        }

        return this.$whitelabel.order.mave_url.replace(this.linkReplacer, '$1');
    }

    get ticketKeeperUrl(): string {
        if (!this.$whitelabel.order.ticket_keeper_url) {
            return '';
        }

        return this.$whitelabel.order.ticket_keeper_url.replace(this.linkReplacer, '$1');
    }

}
</script>

<style lang="scss" scoped>
.order-ticket-actions {
    &:not(:last-child) {
        border-bottom: 2px solid rgba(0, 0, 0, 0.03);
        margin-bottom: var(--ot-spacing-default);
    }

    &__btn {
        margin-bottom: var(--ot-spacing-xs);
        display: flex;

        & span {
            display: flex;
            align-items: center;
        }
        &:last-child {
            margin-bottom: 0;
        }
        & .appic {
            background-color: var(--ot-color-accent-pink-dark);
            & span img {
                height: 20px;
            }
        }
        & .close {
            background-color: var(--ot-shop-color-black);
            & span img {
                // Close wallet app logo is bigger in height because the "l" extends outside the normal flow of the logo
                // That is why it appears to be uncentered, by slightly adjusting the bottom spacing it appears centered now
                margin-bottom: 6px;
            }
        }
        & .party-pay {
            background: hsl(341, 66%, 49%);
            & span img {
                // Partypay wallet app logo is bigger in height because the "y" & "p" extends outside the normal flow of the logo
                // That is why it appears to be uncentered, by slightly adjusting the bottom spacing it appears centered now
                margin-top: 3px;
            }
        }
        & .mave {
            background: hsl(255, 61%, 56%);
            & span img {
                margin: 0 0 0 var(--ot-spacing-xs);
                height: 2.25rem;
            }
        }
        & .ticket-keeper {
            background: hsl(240, 100%, 72%);
            & span img {
                margin: 0 0 0 var(--ot-spacing-xs);
                height: 1.75rem;
            }
        }
        & img {
            max-height: 100%;
            margin-left: var(--ot-spacing-xs);
        }
    }
}
</style>
