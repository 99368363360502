import type {
    CookieKeys,
    PartialCombinedMessages,
    CookieManager,
    CookieStorageContract,
    CookieCategoryValues,
} from '../../composables/cookies';
import { CookieLocalStorage, parseComparableState } from '../../composables/cookies';

export function getCookieMessages(
    vm: Vue,
): null | PartialCombinedMessages<CookieKeys> {
    if (!vm.$te('common.cookie_modal')) {
        return null;
    }

    return vm.$t('common.cookie_modal') as PartialCombinedMessages<CookieKeys>;
}

export async function initCookies(
    manager: CookieManager,
    messages: null | PartialCombinedMessages<CookieKeys>,
): Promise<void> {
    manager.setMessages(messages);

    await manager.load();

    await manager.complete;
}

export class CookieProxyStorage implements CookieStorageContract {

    cookieLocalStorage: CookieLocalStorage;

    constructor() {
        this.cookieLocalStorage = new CookieLocalStorage();

        const urlParams = new URLSearchParams(window.location.search);
        const cookies = urlParams.get('cookies');
        if (cookies) {
            const cookieCategories = parseComparableState(cookies);

            // Save url cookies to storage
            Object.entries(cookieCategories).forEach(
                ([ key, category ]: [string, CookieCategoryValues]) => {
                    if (category.confirmed) {
                        this.cookieLocalStorage.set(key, category.value);
                    }
                },
            );
        }
    }

    get(key: string): boolean | null {
        return this.cookieLocalStorage.get(key);
    }

    set(key: string, value: boolean | null): void {
        this.cookieLocalStorage.set(key, value);
    }

}
