<template>
    <!-- eslint-disable vue/no-v-html -->
    <div
        class="markdown-renderer"
        v-html="renderedContent"
    />
    <!-- eslint-enable vue/no-v-html -->
</template>

<script setup lang="ts">
import { useSlots } from 'vue';
import { parseMarkdown } from '../utils/parseMarkdown';

let renderedContent: string = '';

const slots = useSlots();
const slotContent = slots.default ? slots.default() : null;

if (
    slotContent && Array.isArray(slotContent)
    && slotContent.length > 0
    && typeof slotContent[0] === 'object'
    && slotContent[0].text
) {
    renderedContent = parseMarkdown(slotContent[0].text);
}
</script>

<style lang="scss" scoped>
.markdown-renderer {
    display: inline-block;
}

/* We need the first child P to be inline, it will sometimes be added by the markdown parser */
.markdown-renderer::v-deep > p:first-child {
    margin: 0;
    display: inline;
}
</style>
