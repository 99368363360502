<template>
    <div
        v-if="showCard"
        class="ot-card cover-genius"
    >
        <div class="cover-genius__header">
            <img
                src="../../../../assets/images/icons/shield.svg"
                alt="shield icon"
            >
            <h4>
                {{ $t('order.components.cover_genius.booking.title') }}
            </h4>
        </div>
        <div class="cover-genius__content">
            <div class="cover-genius__content__explanation">
                {{ $t('order.components.cover_genius.booking.explanation') }}
            </div>
            <a
                class="cover-genius__content__claim ot-clickable"
                :href="claimLink"
                target="_blank"
            >
                <div class="cover-genius__content__claim__text">
                    {{
                        $t('order.components.cover_genius.booking.start_claim')
                    }}
                </div>
                <i class="oti oti-link is-small" />
            </a>
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import type { IOrderGlobalProduct } from '@openticket/lib-order';

const productClass = 'CoverGenius';

@Component
export default class CoverGenius extends Vue {

    get showCard(): boolean {
        return !!this.$order.data.global_products.find(
            (product: IOrderGlobalProduct) => product.product.class === productClass,
        );
    }

    get claimLink(): string {
        return this.$whitelabel.order.covergenius_claim_url || 'https://www.xcover.com/';
    }

}
</script>

<style lang="scss">
.cover-genius {
    margin-bottom: 1rem;
    text-align: center;

    &__header {
        margin-bottom: var(--ot-spacing-2xs);

        h4 {
            margin-right: var(--ot-spacing-2xs);
        }
    }

    &__content {
        &__explanation {
            margin-bottom: var(--ot-spacing-sm);
        }

        &__claim {
            display: inline-flex;
            color: var(--ot-card-color) !important;

            i {
                margin: auto 0;
            }

            &__text {
                margin: auto var(--ot-spacing-2xs) auto 0;
                text-decoration: underline;
            }
        }
    }
}
</style>
