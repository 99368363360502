import type { CookieCategoryValues } from './types';
import { type CookieKeys, isValidCookieKey } from './manager';

export function parseComparableState(state: string): { [K in CookieKeys]:CookieCategoryValues } {
    const categories = {} as { [K in CookieKeys]:CookieCategoryValues };

    const entries: string[] = state.split('|');
    entries.forEach((entry: string): void => {
        const [ key, value = '', confirmed = '' ] = entry.split(':');

        if (isValidCookieKey(key)) {
            categories[key] = {
                value: value === 'v1',
                confirmed: confirmed === 'c1',
            };
        }
    });

    return categories;
}
