import './utils';
import './components';

import Vue from 'vue';
import Component from 'vue-class-component';
import VueNotifications from '@openticket/vue-notifications';
import { CustomShopSettingsClient } from '@openticket/lib-custom-shop-settings';
import { Whitelabel } from '@openticket/lib-whitelabels';
import type { WhitelabelInitConfig } from '@openticket/lib-whitelabels';
import '@openticket/vue-promoblock-components/lib/style.css';
import { PromoBlockList } from '@openticket/vue-promoblock-components';
import VueInput from '@openticket/vue-input';
import {
    type Formatters,
    type Parsers,
    type IVueI18n,
    type VueI18n,
    VueLocalization,
} from '@openticket/vue-localization';

import SmoothScroll from 'smoothscroll-polyfill';
import Style from '@openticket/lib-style';
import { CookieManager } from './composables/cookies';
import { CookieProxyStorage } from './pages/order/cookies';

/**
 * Overloads VueI18n interface to avoid needing to cast return value to string.
 * @see https://github.com/kazupon/vue-i18n/issues/410
 */
declare module 'vue-i18n/types' {
    export default class VueI18n {

        t(key: Path, locale: Locale, values?: Values): string;
        t(key: Path, values?: Values): string;

    }
}

declare module 'vue/types/vue' {
    interface Vue {
        $notifications: VueNotifications;
        $settings?: CustomShopSettingsClient;

        $whitelabel: Whitelabel;
        $style: Style;

        $localization: VueLocalization;
        $l: Formatters;
        $p: Parsers;

        readonly $i18n: VueI18n & IVueI18n;
        $t: typeof VueI18n.prototype.t;
        $tc: typeof VueI18n.prototype.tc;
        $te: typeof VueI18n.prototype.te;
        $d: typeof VueI18n.prototype.d;
        $n: typeof VueI18n.prototype.n;
    }
}

declare module 'vue/types/options' {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    interface ComponentOptions<V extends Vue> {
        i18n?: {
            messages?: VueI18n.LocaleMessages;
            dateTimeFormats?: VueI18n.DateTimeFormats;
            numberFormats?: VueI18n.NumberFormats;
            sharedMessages?: VueI18n.LocaleMessages;
        };
    }
}

declare global {
    interface Window {
        CustomShopSettings?: CustomShopSettingsClient;
        Vue: typeof Vue;
    }
}

// Vue is exposed on the window due to Buefy's ProgramaticModal requiring it.
// Alternatively the Buefy plugin could be used, however that would limit tree shaking too much...
// and the Plugins of individual components are not exposed to be registered separately.
// DefineProperty is used due to Buefy's type augmentations
// being mismatched with the Vue 2.7.16 types...
Object.defineProperty(window, 'Vue', {
    get: () => Vue,
});

// Register the router hooks with their names
Component.registerHooks([
    'beforeRouteEnter',
    'beforeRouteLeave',
    'beforeRouteUpdate',
]);

// Make sure scrollTo is smooth on Safari
SmoothScroll.polyfill();

Vue.component('OtPromoBlockList', PromoBlockList);
Vue.use(VueLocalization);

Vue.use(VueInput);

Vue.use(VueNotifications, {
    singleNotification: true,
    duration: 5000,
});

Vue.use(CookieManager, new CookieProxyStorage());

// Add shop settings SDK if URL is provided
const customShopSettings = Vue.observable(new CustomShopSettingsClient());
Object.defineProperty(Vue.prototype, '$settings', {
    get: () => customShopSettings,
});
Object.defineProperty(window, 'CustomShopSettings', {
    get: () => customShopSettings,
});
Vue.mixin({
    provide: {
        settings: customShopSettings,
    },
});

const whitelabelConfig: Partial<WhitelabelInitConfig> = {};

if (import.meta.env.VITE_WHITELABEL_CONFIG_URL) {
    whitelabelConfig.url = import.meta.env.VITE_WHITELABEL_CONFIG_URL;
}

const whitelabel = new Whitelabel(whitelabelConfig);
Object.defineProperty(Vue.prototype, '$whitelabel', {
    get: () => whitelabel,
});
Vue.mixin({
    provide: {
        whitelabel,
    },
});
void whitelabel.loadStylesheet();
void whitelabel.loadIconPack();
void whitelabel.setFavicon();

const style = new Style({});
Object.defineProperty(Vue.prototype, '$style', {
    get: () => style,
});
Vue.mixin({
    provide: {
        style,
    },
});
style.initColorSchemeListener();

Vue.config.productionTip = false;

// Todo: refactor me when vue 3 is implemented
// eslint-disable-next-line max-len
// eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
export const i18n: VueI18n = Vue.prototype.$localization.getI18n();
